import React from "react"
import Vocab from "../../../../components/layouts/vocab.js"

const vocab = require("../../../../components/japanese/data/genki-vocab.json")[8]
const props = {vocabData: vocab, title: "Genki Lesson 9 Vocab Practice (Early Access)" }

const Vocab9 = () => (
    <Vocab pageContext = {props} />
)

export default Vocab9
